import React, { useRef } from 'react';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript

import './Banner.css';

export default class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef(); // Reference for the video
        this.carouselRef = React.createRef(); // Reference for the carousel
        this.state = {
            videoSource: this.getVideoSource(),
            isMobile: window.innerWidth < 768
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

        // Add event listener to trigger the next slide when the video ends
        this.videoRef.current.addEventListener('ended', this.handleVideoEnd);

        // Add listener for slide changes
        this.carouselRef.current.addEventListener('slid.bs.carousel', this.handleSlideChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);

        // Cleanup event listeners
        this.videoRef.current.removeEventListener('ended', this.handleVideoEnd);
        this.carouselRef.current.removeEventListener('slid.bs.carousel', this.handleSlideChange);

        // Clear timeout
        if (this.imageSlideTimeout) {
            clearTimeout(this.imageSlideTimeout);
        }
    }

    
    handleResize = () => {
        this.setState({
            videoSource: this.getVideoSource(),
            isMobile: window.innerWidth < 768
        });
    }


    handleVideoEnd = () => {
        // Move to the next slide after the video ends
        const carousel = this.carouselRef.current;
        if (carousel) {
            const carouselInstance = new bootstrap.Carousel(carousel);
            carouselInstance.next();
        }
    };

    handleSlideChange = () => {
        const activeSlide = this.carouselRef.current.querySelector('.carousel-item.active');
        const isVideoSlide = activeSlide.contains(this.videoRef.current);

        if (isVideoSlide) {
            // Restart the video when transitioning back to the video slide
            this.videoRef.current.currentTime = 0;
            this.videoRef.current.play();
        } else {
            // Schedule the transition to the video slide after 5 seconds for the image slide
            this.imageSlideTimeout = setTimeout(() => {
                const carousel = this.carouselRef.current;
                if (carousel) {
                    const carouselInstance = new bootstrap.Carousel(carousel);
                    carouselInstance.next();
                }
            }, 7000);
        }
    };
    getVideoSource = () => {
        const width = window.innerWidth;
        if (width < 768) {
            return "/videos/414x646.mp4";  // Mobile
        } else if (width < 1024) {
            return "/videos/768x464.mp4";  // Tablet
        }
        return "/videos/1257x759_2.mp4";   // Desktop
    }

    render() {
        return (

            <>
                <div className={'banner-new banner-'+process.env.REACT_APP_WHICH_APP}>

                    <div className="ford">
                        <div id="carouselExample" className="carousel slide" data-bs-touch="true" ref={this.carouselRef}>
                            <div className="carousel-indicators">
                                <button
                                    type="button"
                                    data-bs-target="#carouselExample"
                                    data-bs-slide-to="0"
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"
                                ></button>
                                <button
                                    type="button"
                                    data-bs-target="#carouselExample"
                                    data-bs-slide-to="1"
                                    aria-label="Slide 2"
                                ></button>
                            </div>
                            <div className="carousel-inner">
                                {/* Video Slide */}
                                <div className="carousel-item active">
                                    <div className="d-flex align-items-center justify-content-center h-100">
                                        <video
                                            ref={this.videoRef}
                                            className="video-background"
                                            style={{
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: this.state.isMobile ? '100%' : 'auto'
                                            }}
                                            muted
                                            playsInline
                                            autoPlay
                                        >
                                            <source src={this.state.videoSource} type="video/mp4" />
                                        Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                                {/* Image Slide */}
                                <div className="carousel-item">
                                    <div className="d-inline w-100 h-100">
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundImage: 'url(/images/MEA_Accessories_asset.jpg)',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container d-none d-md-block">
                            <div className="content">
                                <h1>
                                    <span language="en">{process.env.REACT_APP_WHICH_APP} Accessories</span>
                                    <span language="ar">ملحقات {process.env.REACT_APP_WHICH_APP}</span>
                                </h1>
                                <h3>
                                    <span language="ar">هنا تكتمل أناقة سيارتك</span>
                                    <span language="en">Accessorize in Style</span>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="lincoln">
                        <div className="container">
                            <div className="content">
                                <h1>
                                    <span language="en">{process.env.REACT_APP_WHICH_APP} Accessories</span>
                                    <span language="ar">ملحقات {process.env.REACT_APP_WHICH_APP}</span>
                                </h1>
                                <h3>
                                    <span language="ar">هنا تكتمل أناقة سيارتك</span>
                                    <span language="en">Accessorize in Style</span>
                                </h3>
                            </div>
                        </div>
                    </div>

                </div>
            </>

        )
    }
}